<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <div class="d-flex align-center">
      <v-card-title class="text-h5">{{ $t('nav.uv') }}</v-card-title>
      <v-card-title class="ms-auto">
        <ExportExcel :fetch="getExcel" :columns="columns" file-name="uv_chart" />
      </v-card-title>
    </div>

    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <c-input select v-model="search.timeGroupBy" :items="periodItems" :label="$t('shared.active')" search-form />
          <date-picker v-model="search.createdMin" search-form :label="$t('shared.createdMin')" />
          <date-picker v-model="search.createdMax" search-form :label="$t('shared.createdMax')" />
          <auto-complete
            v-if="!userId"
            v-model="search.userId"
            item-text="fullName"
            :search="userSearch"
            :label="$t('users.user')"
            search-form
          />

          <auto-complete
            v-if="!workingSiteId"
            v-model="search.workingSiteId"
            :search="workingSiteSearch"
            :label="$t('workingSites.workingSite')"
            search-form
          />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="getUVData" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>

      <VChart class="mb-5" :data="uv" :options="chartOptions" ref="chart">
        <template #header>
          <div class="mb-4">
            <div class="mb-2">{{ $t('dashboard.uvIndex') }}</div>
            <v-divider></v-divider>
          </div>
        </template>
      </VChart>

      <VChart :data="uvDose" :options="chartOptions" ref="chart">
        <template #header>
          <div class="mb-4">
            <div class="mb-2">{{ $t('dashboard.uvDose') }}</div>
            <v-divider></v-divider>
          </div>
        </template>
      </VChart>
    </v-card-text>
  </v-card>
</template>

<script>
import VChart from '@/components/Charts/VChart'
import moment from 'moment-jalaali'
export default {
  name: 'Dashboard',
  components: {
    VChart
  },
  props: {
    workingSiteId: {type: [Number, String], default: null},
    userId: {type: [Number, String], default: null}
  },
  data() {
    return {
      search: {
        timeGroupBy: 'DAILY',
        createdMax: '2023-01-01T00:00:00',
        createdMin: '2021-01-01T00:00:00',
        userId: this.userId || null,
        workingSiteId: this.workingSiteId || null
      },
      periodItems: [
        {text: this.$t('shared.daily'), value: 'DAILY'},
        {text: this.$t('shared.week'), value: 'WEEKLY'},
        {text: this.$t('shared.month'), value: 'MONTHLY'},
        {text: this.$t('shared.year'), value: 'YEARLY'}
      ],
      uvDose: {
        labels: [],
        datasets: [
          {
            active: true,
            value: 'uvDose',
            label: this.$t('dashboard.uvDose'),
            backgroundColor: '#087979',
            data: []
          }
        ]
      },
      uv: {
        labels: [],
        datasets: [
          {
            active: true,
            value: 'uv',
            label: this.$t('dashboard.uvIndex'),
            backgroundColor: '#f87979',
            data: []
          }
        ]
      },
      chartOptions: {
        maxBarThickness: 40,
        borderRadius: 5,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          annotation: {
            annotations: [
              {
                type: 'line',
                scaleID: 'y',
                borderColor: 'blue',
                backgroundColor: 'blue',
                borderDash: [6, 6],
                borderDashOffset: 0,
                label: {
                  enabled: true,
                  backgroundColor: 'blue',
                  borderRadius: 5,
                  content: (ctx) => 'Max Value: ' + +this.getYMax(ctx).toFixed(3),
                  rotation: 'auto'
                },
                value: (...arg) => this.getYMax(...arg)
              }
            ]
          }
        }
      },
      columns: [
        {field: 'start', title: this.$t('dashboard.start')},
        {field: 'end', title: this.$t('dashboard.end')},
        {field: 'uvAverage', title: this.$t('dashboard.uvAverage')},
        {field: 'uvDoseAverage', title: this.$t('dashboard.uvDoseAverage')}
      ]
    }
  },
  created() {
    this.getUVData()
  },
  methods: {
    async getExcel() {
      this.progressing = true
      const filter = this.$filter()
      try {
        const res = await this.$api.org.reports.getUsersUv(filter)
        return res.uvAverageOuts.map((item, index) => {
          return {
            ...item,
            uvAverage: item.value,
            uvDoseAverage: res.uvDoseAverageOuts[index].value
          }
        })
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    },
    workingSiteSearch(filter) {
      return this.$api.org.workingSites.getAllInfo(filter)
    },
    userSearch(filter) {
      return this.$api.org.users.getAllInfo(filter)
    },
    getUVData() {
      this.progressing = true
      const filter = this.$filter()
      return this.$api.org.reports.getUsersUv(filter).then((res) => {
        this.createUVDoseData(res)
        this.createUVData(res)
        this.progressing = false
      })
    },
    createUVDoseData(model) {
      this.uvDose.labels = model.uvDoseAverageOuts.map((item) => moment.utc(item.start).format('YYYY/MM/DD'))
      this.uvDose.datasets[0].data = model.uvDoseAverageOuts.map((item) => item.value)
    },
    createUVData(model) {
      this.uv.labels = model.uvAverageOuts.map((item) => moment.utc(item.start).format('YYYY/MM/DD'))
      this.uv.datasets[0].data = model.uvAverageOuts.map((item) => item.value)
    },
    getYMax(ctx) {
      const maxes = ctx.chart.data.datasets.map((dataset) => {
        return Math.max(...dataset.data)
      })
      return Math.max(...maxes)
    }
  }
}
</script>
